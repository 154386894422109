import { useSuspenseQuery } from "@tanstack/react-query";
import { permissionChecker } from "@thedealersconcierge/lib/auth";
import { useAtomValue } from "jotai";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import Banner from "~/components/Banner";
import DataIndicatorV2 from "~/components/DataIndicatorV2";
import Spinner from "~/components/Spinner";
import withSuspense from "~/components/withSuspense";
import { gqlQueryClient } from "~/lib/backend";
import { dealershipAtom } from "~/state";

const TransactionCompliance: FC<{
  transactionId: string;
  userId: string;
}> = ({ transactionId, userId }) => {
  const ds = useAtomValue(dealershipAtom);
  const { t } = useTranslation();

  // Left here intentionally to exemplify how to debug and test suspense
  // useSuspenseDebug(1000);

  const canSeeCreditData =
    ds?.activeDealershipPerms &&
    permissionChecker("viewCreditReports", ds.activeDealershipPerms);

  const prequal = useSuspenseQuery({
    queryKey: ["compliance", transactionId],
    queryFn: async () => {
      return await gqlQueryClient()({
        customer: [
          {
            transactionId,
            userId,
          },
          {
            idCards: {
              id: true,
            },
            prequalApplications: [
              { first: 1 },
              {
                edges: {
                  __directives: `@include(if:${canSeeCreditData})`,
                  node: {
                    newestReportPrequalify: {
                      ofacSummary: true,
                      redFlagSummary: true,
                      quizStatus: true,
                    },
                  },
                },
              },
            ],
          },
        ],
      });
    },
  });

  const hasIdCard = Boolean(prequal.data?.customer?.idCards?.at(0)?.id);

  const ofacStatus =
    prequal.data?.customer?.prequalApplications?.edges?.at(0)?.node
      ?.newestReportPrequalify?.ofacSummary;

  const redFlagStatus =
    prequal.data?.customer?.prequalApplications?.edges?.at(0)?.node
      ?.newestReportPrequalify?.redFlagSummary;

  const idQuizSummary =
    prequal.data?.customer?.prequalApplications?.edges?.at(0)?.node
      ?.newestReportPrequalify?.quizStatus;

  return (
    <div className="rounded-2xl bg-white shadow-md p-8 space-y-8 size-full">
      <div className="flex flex-row space-x-2 items-center">
        <h2 className="text-heading-1">Compliance</h2>
      </div>

      {!canSeeCreditData && (
        <Banner variant="WARNING">
          <p>{t("You don't have access to see compliance data")}</p>
        </Banner>
      )}

      {canSeeCreditData && (
        <div className="grid grid-cols-3 gap-4">
          {/**
           * ID, whether ID has been supplied
           */}
          <div className="flex flex-row justify-start items-center">
            <DataIndicatorV2 val={hasIdCard ? "positive" : "negative"} />

            <div className="text-body text-dark-gray pl-6">ID</div>
          </div>

          {/**
           * ID Quiz summary
           */}
          <div className="flex flex-row justify-start items-center pl-6">
            <DataIndicatorV2
              val={
                idQuizSummary
                  ? redFlagStatus === "Clear"
                    ? "positive"
                    : "negative"
                  : "warn"
              }
            />
            <div className="text-body text-dark-gray pl-6">{t("ID Quiz")}</div>
          </div>

          <div />

          {/**
           * Red flag
           */}
          <div className="flex flex-row justify-start items-center">
            <DataIndicatorV2
              val={
                redFlagStatus
                  ? redFlagStatus === "Clear"
                    ? "positive"
                    : "negative"
                  : "warn"
              }
            />

            <div className="text-body text-dark-gray pl-6">{t("Red Flag")}</div>
          </div>

          {/**
           * OFAC
           */}
          <div className="flex flex-row justify-start items-center pl-6">
            <DataIndicatorV2
              val={
                ofacStatus
                  ? ofacStatus === "Clear"
                    ? "positive"
                    : "negative"
                  : "warn"
              }
            />

            <div className="text-body text-dark-gray pl-6">OFAC</div>
          </div>

          <div />
        </div>
      )}

      {/* {!isCoBuyer && (
        <Button variant="SECONDARY" size="SMALL" onClick={handleShowId}>
          View ID
        </Button>
      )} */}
    </div>
  );
};

export default withSuspense(
  TransactionCompliance,
  <div className="rounded-2xl bg-white shadow-md flex size-full justify-center align-middle">
    <Spinner />
  </div>
);
