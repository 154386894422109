import { useForm } from "@tanstack/react-form";
import { useQuery } from "@tanstack/react-query";
import { VehicleConditionLiterals } from "@thedealersconcierge/lib/codecs/tdc";
import { useAtomValue } from "jotai";
import { FC, Fragment, useMemo, useState } from "react";
import { VehicleType } from "~/__generated__/backend/zeus";
import updateVehicleAction from "~/actions/vehicles/updateVehicleAction";
import { BreadCrumb, BreadCrumbsContainer } from "~/components/BreadCrumbs";
import Button from "~/components/Button";
import FormInputField from "~/components/FormInputField";
import Spinner from "~/components/Spinner";
import Typeahead, { TypeaheadOption } from "~/components/inputs/Typeahead";
import vehicleBodyTypeOptions, {
  parseAndValidateBodyType,
} from "~/config/formSelectionOptions/vehicleBodyTypeOptions";
import { gqlMutationClient } from "~/lib/backend";
import { queryClient } from "~/lib/query";
import ErrorBanner from "~/pages/_components/ErrorBanner";
import { Link, useNavigate, useParams } from "~/router";
import { VehicleType as Vehicle } from "~/selectors/vehicleSelector";
import { dealershipAtom } from "~/state";
import {
  TransactionQueryType,
  transactionQuery,
} from "../../_queries/transactionQuery";
import {
  SingleHomenetVehicleData,
  fetchHomenetVehicleDataByStockNumber,
} from "./_queries/fetchHomenetByStocknumberQuery";

const VehiclePageContent: FC<{
  transactionId: string;
  vehicleType: VehicleType;
  transaction?: TransactionQueryType["transaction"];
}> = ({ transactionId, vehicleType, transaction }) => {
  const queryParams = useMemo(() => {
    return new URLSearchParams(window.location.search);
  }, []);
  const [error, setError] = useState<null | string>(null);
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [onSelectVehicle, setOnSelectVehicle] =
    useState<SingleHomenetVehicleData | null>(null);

  // The transaction query uses the vehicle selector, therefore we can use its type here
  let vehicle: Partial<Vehicle | undefined> =
    vehicleType === VehicleType.PURCHASE
      ? transaction?.vehicle
      : transaction?.tradeVehicle;

  // This prevents data resetted to default value when on edit
  if (onSelectVehicle) {
    const bodyType = vehicleBodyTypeOptions.find(
      (el) => el.label === onSelectVehicle.bodyType
    );
    // TODO: Use zod validate to validate and transform the input of body type instead of using a wrapper function
    vehicle = {
      id: vehicle?.id,
      stockNumber: onSelectVehicle.stockNumber,
      vin: onSelectVehicle.vin,
      bodyType: parseAndValidateBodyType(bodyType?.value),
      make: onSelectVehicle.make,
      model: onSelectVehicle.model,
      year: onSelectVehicle.year,
      color: onSelectVehicle.color,
      mileage: onSelectVehicle.mileage ? Number(onSelectVehicle.mileage) : 0,
      trim: onSelectVehicle.trim,
      isUsed: onSelectVehicle.type
        ? onSelectVehicle.type.toLowerCase() === "used"
        : false,
    };
  }

  const handleCancel = () => {
    navigate("/dashboard/transaction/:transactionId", {
      params: { transactionId },
    });
  };

  const form = useForm({
    defaultValues: {
      stockNumber: vehicle?.stockNumber ?? "",
      vin: vehicle?.vin ?? "",
      bodyType: vehicle?.bodyType ?? "",
      make: vehicle?.make ?? "",
      model: vehicle?.model ?? "",
      year: vehicle?.year ?? "",
      color: vehicle?.color ?? "",
      condition: vehicle?.condition,
      isUsed:
        vehicle?.isUsed !== undefined ? (vehicle.isUsed ? "USED" : "NEW") : "",
      mileage: vehicle?.mileage ? Number(vehicle.mileage) : 0,
      trim: vehicle?.trim ?? "",
    },
    onSubmit: async (values) => {
      try {
        setIsSubmitting(true);

        if (!queryParams.get("create")) {
          if (!vehicle?.id) {
            throw new Error("No vehicle to update");
          }

          await updateVehicleAction(transactionId, vehicleType, vehicle.id, {
            ...values,
            isUsed: values.isUsed ? values.isUsed === "USED" : undefined,
            mileage: values.mileage ? Number(values.mileage) : undefined,
            bodyType: values.bodyType === "" ? undefined : values.bodyType,
            stockNumber: searchStockNumber,
            condition: undefined,
          });

          await queryClient.invalidateQueries({
            queryKey: ["transaction", transactionId],
          });

          navigate("/dashboard/transaction/:transactionId", {
            params: { transactionId },
          });
        } else {
          const resp = await gqlMutationClient()({
            createVehicle: [
              {
                transactionId,
                vehicleType,
                vehicle: {
                  ...values,
                  isUsed: values.isUsed ? values.isUsed === "USED" : undefined,
                  mileage: values.mileage ? Number(values.mileage) : undefined,
                  bodyType:
                    values.bodyType === "" ? undefined : values.bodyType,
                  stockNumber: searchStockNumber,
                },
              },
              {
                __typename: true,
                "...on GraphQLError": {
                  message: true,
                },
                "...on MutationCreateVehicleSuccess": {
                  data: {
                    status: true,
                  },
                },
              },
            ],
          });

          if (
            !resp.createVehicle ||
            resp.createVehicle.__typename === "GraphQLError"
          ) {
            setError(resp.createVehicle?.message ?? "Could not update vehicle");
          } else {
            await queryClient.invalidateQueries({
              queryKey: ["transaction", transactionId],
            });
            navigate("/dashboard/transaction/:transactionId", {
              params: { transactionId },
            });
          }
        }
      } catch (e: any) {
        setError(e.message ?? "Could not update vehicle");
      } finally {
        setIsSubmitting(false);
      }
    },
  });
  const dealership = useAtomValue(dealershipAtom);

  const [searchStockNumber, setSearchStockNumber] = useState(
    vehicle?.stockNumber ?? ""
  );
  const { data, isLoading } = useQuery(
    fetchHomenetVehicleDataByStockNumber(
      dealership?.activeDealershipPerms.dealershipId,
      searchStockNumber
    )
  );

  const vehicleOptions: TypeaheadOption<SingleHomenetVehicleData>[] =
    data?.dealership?.homenetVehicles?.edges
      ?.map((node) => {
        const e = node.node;
        return {
          key: e?.stockNumber ?? e?.id ?? "no-key",
          label: `${e?.stockNumber}`,
          subtext: `${e?.make} ${e?.model} (${e?.year}) - ${e?.color}, ${e?.bodyType}`,
          value: e,
        };
      })
      ?.filter(
        (e): e is TypeaheadOption<SingleHomenetVehicleData> => !!e.value
      ) ?? [];

  const handleSelect = (
    selected: TypeaheadOption<SingleHomenetVehicleData>
  ) => {
    const bodyType = vehicleBodyTypeOptions.find(
      (el) => el.label === selected.value.bodyType
    );

    const stockNumber = selected.value.stockNumber ?? "";
    form.setFieldValue("stockNumber", stockNumber);
    form.setFieldValue("vin", selected.value.vin ?? "");
    form.setFieldValue("make", selected.value.make ?? "");
    form.setFieldValue("model", selected.value.model ?? "");
    form.setFieldValue("year", selected.value.year ?? "");
    form.setFieldValue("color", selected.value.color ?? "");
    form.setFieldValue("isUsed", selected.value.type ? "USED" : "NEW");
    form.setFieldValue(
      "mileage",
      selected.value.mileage ? Number(selected.value.mileage) : 0
    );
    form.setFieldValue("trim", selected.value.trim ?? "");
    form.setFieldValue("bodyType", bodyType?.value ?? "");
    setSearchStockNumber(stockNumber);
    setOnSelectVehicle(selected.value);
  };

  return (
    <Fragment>
      <BreadCrumbsContainer>
        <BreadCrumb title="Transaction">
          <Link to={"/dashboard"}>Transactions</Link>
        </BreadCrumb>

        <BreadCrumb title="Users">
          <Link
            to={"/dashboard/transaction/:transactionId"}
            params={{ transactionId }}
          >
            {transaction?.title}
          </Link>
        </BreadCrumb>

        <BreadCrumb title="Co-Buyer">
          <span>
            {vehicleType === VehicleType.PURCHASE
              ? "Purchase Vehicle"
              : "Trade Vehicle"}
          </span>
        </BreadCrumb>
      </BreadCrumbsContainer>

      <div className="flex justify-center p-8 overflow-y-scroll h-full">
        <div className="flex w-1/2 min-w-fit flex-col bg-white rounded-xl shadow-md p-10 space-y-6">
          <h2 className="text-heading-2">
            {vehicleType === VehicleType.PURCHASE
              ? "Purchase Vehicle"
              : "Trade Vehicle"}
          </h2>

          {error && <ErrorBanner text={error} />}

          <form.Provider>
            <form
              className="flex flex-col space-y-8"
              onSubmit={async (e) => {
                e.preventDefault();
                e.stopPropagation();
                void form.handleSubmit();
              }}
            >
              <div className="grid grid-cols-2 gap-6 w-full">
                {vehicleType === VehicleType.PURCHASE && (
                  <div className="col-span-2 w-full">
                    <div className="flex flex-row gap-4 w-full">
                      <div className="flex-grow">
                        <div className="w-full">
                          {form && (
                            <Typeahead
                              options={vehicleOptions}
                              onSelect={handleSelect}
                              onSearch={(searchString: string) => {
                                setSearchStockNumber(searchString);
                              }}
                              title="Enter Stock Number"
                              isLoading={isLoading}
                              selectedItemKey={vehicle?.stockNumber} // To preselect
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {vehicleType === VehicleType.TRADE && (
                  <FormInputField
                    form={form}
                    fieldName="condition"
                    type="select"
                    labelText="Condition"
                    placeholder="Condition"
                    subtitleText="Condition"
                    options={VehicleConditionLiterals.map((l) => {
                      // This is an experimental approach to having more typesafe
                      // forms, let me know how well it works
                      const label =
                        l._def.value.at(0)?.toLocaleUpperCase() +
                        l._def.value.slice(1).toLocaleLowerCase();

                      return {
                        value: l._def.value,
                        label,
                      };
                    })}
                    disabled={isSubmitting}
                  />
                )}

                {/* TODO: Refactor into something typesafe */}
                <FormInputField
                  form={form}
                  fieldName="isUsed"
                  type="select"
                  labelText="Type"
                  placeholder="Type"
                  subtitleText="Type"
                  options={[
                    { value: "USED", label: "Used" },
                    { value: "NEW", label: "New" },
                  ]}
                  disabled={isSubmitting}
                />

                {/* TODO: Refactor into something typesafe */}
                <FormInputField
                  form={form}
                  fieldName="vin"
                  type="text"
                  labelText="VIN#"
                  placeholder="VIN#"
                  subtitleText="VIN#"
                  disabled={isSubmitting}
                />

                {/* TODO: Refactor into something typesafe */}
                <FormInputField
                  form={form}
                  fieldName="bodyType"
                  type="select"
                  labelText="Body Type"
                  placeholder="Body Type"
                  subtitleText="Body Type"
                  options={vehicleBodyTypeOptions}
                  disabled={isSubmitting}
                />

                {/* TODO: Refactor into something typesafe */}
                <FormInputField
                  form={form}
                  fieldName="make"
                  type="text"
                  labelText="Make"
                  placeholder="Make"
                  subtitleText="Make"
                  disabled={isSubmitting}
                />

                {/* TODO: Refactor into something typesafe */}
                <FormInputField
                  form={form}
                  fieldName="model"
                  type="text"
                  labelText="Model"
                  placeholder="Model"
                  subtitleText="Model"
                  disabled={isSubmitting}
                />

                {/* TODO: Refactor into something typesafe */}
                <FormInputField
                  form={form}
                  fieldName="year"
                  type="text"
                  labelText="Year"
                  placeholder="Year"
                  subtitleText="Year"
                  disabled={isSubmitting}
                />

                {vehicleType === VehicleType.TRADE && (
                  // TODO: Refactor into something typesafe
                  <FormInputField
                    form={form}
                    fieldName="color"
                    type="text"
                    labelText="Color"
                    placeholder="Color"
                    subtitleText="Color"
                    disabled={isSubmitting}
                  />
                )}

                {/* TODO: Refactor into something typesafe */}
                <FormInputField
                  form={form}
                  fieldName="mileage"
                  type="number"
                  labelText="Mileage"
                  placeholder="Mileage"
                  subtitleText="Mileage"
                  disabled={isSubmitting}
                />

                {vehicleType === VehicleType.PURCHASE && (
                  // TODO: Refactor into something typesafe
                  <FormInputField
                    form={form}
                    fieldName="trim"
                    type="text"
                    labelText="Trim"
                    placeholder="Trim"
                    subtitleText="Trim"
                    disabled={isSubmitting}
                  />
                )}
              </div>

              <div className="flex flex-row justify-between">
                <Button
                  variant="TERTIARY"
                  type="button"
                  disabled={isSubmitting}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>

                <Button type="submit" loading={isSubmitting}>
                  Save
                </Button>
              </div>
            </form>
          </form.Provider>
        </div>
      </div>
    </Fragment>
  );
};

const VehiclePage = () => {
  const { transactionId, vehicleType } = useParams(
    "/dashboard/transaction/:transactionId/vehicle/:vehicleType"
  );

  const dealership = useAtomValue(dealershipAtom);
  const { data: transactionData, isLoading } = useQuery(
    transactionQuery(transactionId, dealership?.activeDealershipPerms)
  );

  return (
    <div className="flex flex-col space-y-4 max-h-dvh overflow-hidden">
      {!isLoading && transactionData?.transaction ? (
        <VehiclePageContent
          transactionId={transactionId}
          vehicleType={
            vehicleType.toUpperCase() === "PURCHASE"
              ? VehicleType.PURCHASE
              : VehicleType.TRADE
          }
          transaction={transactionData.transaction}
        />
      ) : (
        <div className="flex justify-center min-h-dvh">
          <Spinner />
        </div>
      )}
    </div>
  );
};

export default VehiclePage;
