import { useForm } from "@tanstack/react-form";
import { permissionChecker } from "@thedealersconcierge/lib/auth";
import { UserRole } from "@thedealersconcierge/lib/codecs/tdc";
import { useAtomValue } from "jotai";
import { toast } from "react-toastify";
import Button from "~/components/Button";
import FormInputField from "~/components/FormInputField";
import PhoneNumberInput from "~/components/PhoneNumberInput";
import staffRoleOptions from "~/config/formSelectionOptions/staffRoleOptions";
import { gqlMutationClient } from "~/lib/backend";
import { dealershipAtom } from "~/state";
import Modal from "../../../components/Modal";
import { useModals, useNavigate } from "../../../router";

type NewStaff = {
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  email?: string;
  role?: UserRole;
};

export default function CreatePage() {
  const dealership = useAtomValue(dealershipAtom);

  const canManageUser = dealership?.activeDealershipPerms
    ? permissionChecker("manageUsers", dealership?.activeDealershipPerms)
    : false;

  const { close } = useModals();
  const navigate = useNavigate();
  const form = useForm<NewStaff, unknown>({
    onSubmit: async (values) => {
      if (!canManageUser) {
        toast.error("You don't have permissions to create users");
        return;
      }
      if (!dealership?.activeDealershipPerms.dealershipId) {
        throw new Error("No dealership activte");
      }

      const createStaffResponse = await gqlMutationClient()({
        createStaff: [
          {
            dealershipId: dealership.activeDealershipPerms.dealershipId,
            user: {
              firstName: values.firstName!,
              lastName: values.lastName!,
              phoneNumber: values.phoneNumber!,
              email: values.email!,
              role: values.role!,
            },
          },
          {
            __typename: true,
            "...on GraphQLError": {
              message: true,
            },
            "...on MutationCreateStaffSuccess": {
              data: {
                id: true,
              },
            },
          },
        ],
      });

      if (
        createStaffResponse.createStaff?.__typename ===
        "MutationCreateStaffSuccess"
      ) {
        toast.success("New user created");
        navigate("/dashboard/users/:userId", {
          params: {
            userId: createStaffResponse.createStaff.data.id ?? "no-user-id",
          },
        });
      } else {
        toast.error(createStaffResponse.createStaff?.message);
      }
    },
  });

  return (
    <Modal
      title="Create New User"
      containerClassName="w-1/2"
      isOpen
      onClose={close}
    >
      {canManageUser ? (
        <div className="p-10 flex flex-col h-full">
          <form.Provider>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                e.stopPropagation();
                void form.handleSubmit();
              }}
              className="flex flex-col space-y-8 h-full"
            >
              <h2 className="text-heading-2 ml-2">Enter Staff Information</h2>

              <div className="flex flex-col space-y-4">
                <div className="grid grid-cols-2 gap-4">
                  <FormInputField
                    form={form}
                    fieldName="firstName"
                    type="text"
                    labelText="First Name"
                    subtitleText="First Name"
                    placeholder="First Name"
                    required
                  />

                  <FormInputField
                    form={form}
                    fieldName="lastName"
                    type="text"
                    labelText="Last Name"
                    subtitleText="Last Name"
                    placeholder="Last Name"
                    required
                  />
                </div>

                <PhoneNumberInput
                  form={form}
                  fieldName="phoneNumber"
                  subtitleText="Phone Number"
                  required
                />

                <FormInputField
                  form={form}
                  fieldName="email"
                  type="email"
                  labelText="Email"
                  subtitleText="Email"
                  placeholder="Email"
                  required
                />

                <FormInputField
                  form={form}
                  fieldName="role"
                  type="select"
                  labelText="Role"
                  subtitleText="Role"
                  placeholder="Role"
                  options={staffRoleOptions}
                  required
                />
              </div>

              <div className="flex flex-row justify-between">
                <Button variant="SECONDARY" onClick={() => close()}>
                  Cancel
                </Button>

                <form.Subscribe
                  selector={(state) => ({
                    values: state.values,
                    isValid: state.isValid,
                  })}
                  children={({ values, isValid }) => {
                    const canContinue =
                      isValid &&
                      !!values.firstName &&
                      !!values.lastName &&
                      !!values.email &&
                      !!values.phoneNumber &&
                      !!values.role;

                    return (
                      <Button
                        type="submit"
                        disabled={!canContinue || !canManageUser}
                      >
                        Create User
                      </Button>
                    );
                  }}
                />
              </div>
            </form>
          </form.Provider>
        </div>
      ) : (
        <div className="p-10 flex flex-col h-full">
          You don't have enough permission to create user!
        </div>
      )}
    </Modal>
  );
}
